import axios from "axios";
import _ from 'lodash';
import LocalesUtils from './../utils/LocalesUtils';
import NumberUtils from './../utils/NumberUtils';
import {t} from "../utils/Translation";
import moment from "moment";

let BatchService = {

    actions: {
        EXTERNAL_ENTRY: 'EXTERNAL_ENTRY',
        EXTERNAL_SALE: 'EXTERNAL_SALE',
        TRANSFER: 'TRANSFER',
        DEATH: 'DEATH',
        BUY: 'BUY',
        ENTRY: 'ENTRY',
        EXIT: 'EXIT'
    },

    batchDetailsTabParam: {
        totals: 0,
        movements: 1,
        feed: 2,
        events: 3,
        tasks: 4,
        weight: 5
    },

    batchSimpleGrowthAllInAllOutDetailsTabParam: {
        totals: 0,
        movements: 1,
        feed: 2,
        events: 3,
        tasks: 4,
        weight: 5
    },

    batchSimpleGrowthContinuousDetailsTabParam: {
        totals: 0,
        movements: 1,
        feed: 2,
        events: 3,
        tasks: 4
    },

    productionTypeId: {
        default: "3fe0b290-4a9c-4196-8957-3262471798e7"
    },

    getFeedMovementsAction(){
        return [{
            id: 'ENTRY',
            name: t('batches.details.feedMovements.list.searchBox.fields.action.options.entry')
        }, {
            id: 'EXIT',
            name: t('batches.details.feedMovements.list.searchBox.fields.action.options.exit')
        }];
    },

    getFeedMovementsActionType(){
        return [{
            id: 'TRANSFER',
            name: t('batches.details.feedMovements.list.searchBox.fields.actionType.options.transfer')
        }, {
            id: 'BUY',
            name: t('batches.details.feedMovements.list.searchBox.fields.actionType.options.buy')
        }];
    },


    loadBatches: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        if(!_.isNil(searchData.status)) {
            searchData.status = searchData.status === 'OPEN';
        }

        axios.get('/batches/search', {
            params: searchData
        }).then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        );
    },

    deleteBatch: function (batchId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.delete('/batches/' + batchId).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getProductionSpecies: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/productionSpecies').then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getProductionTypes: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/productionTypes').then((response) => {
            onSuccess(BatchService.getProductionTranslatedAndZippedTypes(response.data));
        }, (error) => {
            onError(error);
        });
    },

    createBatch: function (batchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        if (!_.isNil(batchData.dropzone)) {
            batchData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        batchData.dropzone = null;
        formBox.append('createBatchRequest', new Blob([JSON.stringify(batchData)], { type: "application/json" }));
        axios.post('/batches', formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getBatchDetails: function (batchId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/' + batchId).then(response => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editBatch: function (batchId, batchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        if (!_.isNil(batchData.dropzone)) {
            batchData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        batchData.dropzone = null;
        formBox.append('editBatchRequest', new Blob([JSON.stringify(batchData)], { type: "application/json" }));
        axios.put('/batches/' + batchId, formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    quickCloseOrOpenBatch(batchId, data, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/batches/' + batchId + '/quickCloseOpen', data).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    refreshEnvironmentKpis(batchId, statusChange, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {
            // This is intentional
        };
        onError = onError ? onError : () => {
            // This is intentional
        };

        axios.get('/batches/updateEnvironmentKpis?batchId=' + batchId + '&statusChange=' + statusChange).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getActionOptions: function () {
        return [
            {
                id: BatchService.actions.EXTERNAL_ENTRY,
                name: t('batches.details.transactions.form.fields.actionType.options.externalEntry')
            }, {
                id: BatchService.actions.EXTERNAL_SALE,
                name: t('batches.details.transactions.form.fields.actionType.options.externalSale')
            }, {
                id: BatchService.actions.TRANSFER,
                name: t('batches.details.transactions.form.fields.actionType.options.transfer')
            }, {
                id: BatchService.actions.DEATH,
                name: t('batches.details.transactions.form.fields.actionType.options.death')
            }
        ];
    },

    getListBatches: function (listParameters, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get('/batches', {
            params: listParameters
        }).then(
            (response) => {
                onSuccess(response.data);
            },
            (error) => {
                onError(error);
            }
        );
    },

    getBatchFile(batchId, filename, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.get('/static/batches/' + batchId + '/attachments/' + filename, {
            responseType: 'arraybuffer'
        }).then(response => {
            onSuccess(response);
        }, error => {
            onError(error);
        });
    },

    getBatchFiles(batchId, onSuccess, onError) {
        axios.get('/batches/' +  batchId + '/attachments/').then((response) => {
            if (onSuccess) {
                let files = [];
                response.data.forEach(file => {
                    let fileType = (!_.isNil(file.contentBase64)) ? file.contentBase64.split(';')[0] : '';
                    fileType = fileType.replace('data:', '');
                    let newFile = new File([""], file.name, {type: fileType});

                    if (fileType.indexOf('image') !== -1){
                        newFile.preview = '/services/static/batches/' + batchId + '/attachments/' + file.name;
                    }else{
                        newFile.preview = file.contentBase64;
                    }
                    files.push(newFile);
                });
                onSuccess(files);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getDeathReasons: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get('/batches/productionDeathReasons').then(
            (response) => {
                onSuccess(response.data);
            },
            (error) => {
                onError(error);
            }
        );
    },

    // Batch Transactions

    getFeedMovementsActionOptions: function () {
        return [
            {
                id: BatchService.actions.TRANSFER,
                name: t('batches.details.feedMovements.form.fields.actionType.options.transfer')
            }, {
                id: BatchService.actions.BUY,
                name: t('batches.details.feedMovements.form.fields.actionType.options.buy')
            }
        ];
    },

    createBatchTransaction: function (batchTransactionData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        if (!_.isNil(batchTransactionData.dropzone)) {
            batchTransactionData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        batchTransactionData.dropzone = null;
        formBox.append('createAnimalTransactionRequest', new Blob([JSON.stringify(batchTransactionData)], { type: "application/json" }));
        axios.post('/batches/transactions', formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editBatchTransaction: function (batchTransactionId, batchTransactionData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        if (!_.isNil(batchTransactionData.dropzone)) {
            batchTransactionData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        batchTransactionData.dropzone = null;
        formBox.append('updateAnimalTransactionRequest', new Blob([JSON.stringify(batchTransactionData)], { type: "application/json" }));
        axios.put('/batches/transactions/' + batchTransactionId, formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getBatchTransactionDetails: function (transactionId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/transactions/' + transactionId).then((response) => {
            response.data.status = !response.data.pending;
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getBatchTransactions: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/transactions', {
            params: searchData
        }).then(response => onSuccess(response.data),
            error => onError(error)
        );
    },

    getLastBatchId(currentBatchId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };
        axios.get('/batches/'+ currentBatchId + '/last-batch')
            .then(response => onSuccess(response.data), error => onError(error))
    },

    loadBatchTransactions: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/transactions/search', {
            params: searchData
        }).then(
            (response) => {
                _.forEach(response.data.content, batchTransaction => {
                    batchTransaction.status = !batchTransaction.pending;
                    batchTransaction.actionDescription = getActionDescriptionFromTransaction(batchTransaction);
                    batchTransaction.totalWeight = batchTransaction.weight? parseFloat(batchTransaction.weight) : null;
                    batchTransaction.avgWeight = batchTransaction.totalWeight? batchTransaction.totalWeight/parseInt(batchTransaction.number, 10) : null;
                    batchTransaction.numberAnimals = _.clone(batchTransaction.number);
                    batchTransaction.numberRules = {
                        entry: batchTransaction.action ==='ENTRY'?  1: 0,
                        value: batchTransaction.number
                    };
                    let actionType = _.find(BatchService.getActionOptions(), {'id': batchTransaction.actionType});
                    batchTransaction.actionTypeEnum = batchTransaction.actionType;
                    batchTransaction.actionType = !_.isNil(actionType) ? actionType.name : null;
                });
                onSuccess(response.data);
            },
            (error) => {
                onError(error);
            }
        );
    },

    deleteBatchTransaction: function(transactionId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.delete('/batches/transactions/' + transactionId).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getBatchTransactionFile(transactionId, filename, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.get('/static/batches/transactions/' + transactionId + '/attachments/' + filename, {
            responseType: 'arraybuffer'
        }).then(response => {
            onSuccess(response);
        }, error => {
            onError(error);
        });
    },

    getAnimalTransactionFiles(transactionId, onSuccess, onError) {
        axios.get('/batches/transactions/' + transactionId + '/attachments/').then((response) => {
            if (onSuccess) {
                let files = [];
                response.data.forEach(file => {
                    let fileType = (!_.isNil(file.contentBase64)) ? file.contentBase64.split(';')[0] : '';
                    fileType = fileType.replace('data:', '');
                    let newFile = new File([""], file.name, {type: fileType});

                    if (fileType.indexOf('image') !== -1){
                        newFile.preview = '/services/static/batches/transactions/' + transactionId + '/attachments/' + file.name;
                    }else{
                        newFile.preview = file.contentBase64;
                    }
                    files.push(newFile);
                });
                onSuccess(files);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    // Feed Transactions

    createFeedTransaction: function (feedTransactionData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        if (!_.isNil(feedTransactionData.dropzone)) {
            feedTransactionData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        feedTransactionData.dropzone = null;
        formBox.append('createFeedTransactionRequest', new Blob([JSON.stringify(feedTransactionData)], { type: "application/json" }));
        axios.post('/batches/feedTransactions', formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editFeedTransaction: function (feedTransactionId, feedTransactionData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        if (!_.isNil(feedTransactionData.dropzone)) {
            feedTransactionData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        feedTransactionData.dropzone = null;
        formBox.append('updateFeedTransactionRequest', new Blob([JSON.stringify(feedTransactionData)], { type: "application/json" }));
        axios.put('/batches/feedTransactions/' + feedTransactionId, formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getFeedTransactionDetails: function (transactionId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/feedTransactions/' + transactionId).then((response) => {
            if(response.data.feedType) {
                response.data.feedTypeId = response.data.feedType.id;
            }
            response.data.status = !response.data.pending;
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    loadFeedTransactions: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/batches/feedTransactions/search', {
            params: searchData
        }).then(
            (response) => {
                _.forEach(response.data.content, feedTransaction => {
                    feedTransaction.status = !feedTransaction.pending;
                    feedTransaction.weight = NumberUtils.formatDecimalSeparator(feedTransaction.weight, 0);
                    feedTransaction.actionDescription = getActionDescriptionFromTransaction(feedTransaction);
                    feedTransaction.weightRules = {
                        entry: feedTransaction.action ==='ENTRY'?  1: 0,
                        value: feedTransaction.weight
                    };
                    let actionType = _.find(BatchService.getFeedMovementsActionType(), {'id': feedTransaction.actionType});
                    feedTransaction.actionTypeEnum = feedTransaction.actionType;
                    feedTransaction.actionType = !_.isNil(actionType) ? actionType.name : null;
                });
                onSuccess(response.data);
            },
            (error) => {
                onError(error);
            }
        );
    },

    loadAutomaticFeedTransactions: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        if(!_.isNil(searchData.status)) {
            searchData.status = searchData.status === 'OPEN';
        }

        this.mapSearchDataLastTime(searchData)

        axios.get('/batches/feedTransactions/automatic-feed-movements/search', {
            params: searchData
        }).then(
            (response) => {
                onSuccess(response.data);
            },
            (error) => {
                onError(error);
            }
        );
    },

    deleteFeedTransaction: function(transactionId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.delete('/batches/feedTransactions/' + transactionId).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getFeedTransactionFile(transactionId, filename, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.get('/static/batches/feedTransactions/' + transactionId + '/attachments/' + filename, {
            responseType: 'arraybuffer'
        }).then(response => {
            onSuccess(response);
        }, error => {
            onError(error);
        });
    },

    getFeedTransactionFiles(transactionId, onSuccess, onError) {
        axios.get('/batches/feedTransactions/' + transactionId + '/attachments/').then((response) => {
            if (onSuccess) {
                let files = [];
                response.data.forEach(file => {
                    let fileType = (!_.isNil(file.contentBase64)) ? file.contentBase64.split(';')[0] : '';
                    fileType = fileType.replace('data:', '');
                    let newFile = new File([""], file.name, {type: fileType});

                    if (fileType.indexOf('image') !== -1){
                        newFile.preview =  '/services/static/batches/feedTransactions/' + transactionId + '/attachments/' + file.name;
                    }else{
                        newFile.preview = file.contentBase64;
                    }
                    files.push(newFile);
                });
                onSuccess(files);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getBatchTransactionAgeOptions() {
        return [
            {id: 1, name: t('batches.details.transactions.form.fields.age.options.day')} ,
            {id: 7, name: t('batches.details.transactions.form.fields.age.options.week')}
        ];
    },

    // ---------

    getSpeciesAndSubspeciesName(species) {
        let result = '';
        if(species) {
            if(species.species) {
                result += BatchService.getSpeciesAndSubspeciesName(species.species) + ' | ';
            }
            if(species.translations) {
                result += LocalesUtils.getLocaleString(species.translations);
            } else {
                result += _.capitalize(species.value);
            }
        } else {
            result = '- - -';
        }
        return result;
    },

    getProductionTranslatedAndZippedTypes(productionTypes) {
        let result = [];
        _.forEach(productionTypes, type => {
            result.push(BatchService.getProductionType(type));
        });
        return result;
    },

    getProductionType(type) {
        if(type) {
            return {
                id: type.id,
                maxBalance: type.maxBalance,
                name: LocalesUtils.getLocaleString(type.mode.translations) + ' | ' + LocalesUtils.getLocaleString(type.subMode.translations)
            };
        } else {
            return {};
        }
    },

    getProductionBatchCurveDays(batchId, onSuccess = () => { }, onError = () => { }) {
        axios.get('/batches/' + batchId + '/curveWindowRange').then(
            response => {
                onSuccess(response.data);
            },
            error => {
                onError(error);
            }
        )
    },

    exportBatchReport(batchId, onSuccess = () => { }, onError = () => { }) {
        axios.get('/reports/batches/' + batchId, {
            responseType: 'arraybuffer'
        }).then(
            response => {
                onSuccess(response);
            },
            error => {
                onError(error);
            }
        )
    },

    exportBatchRawReport(batchId, onSuccess = () => { }, onError = () => { }) {
        axios.get('/reports/batches/' + batchId + "/raw", {
            responseType: 'arraybuffer'
        }).then(
            response => {
                onSuccess(response);
            },
            error => {
                onError(error);
            }
        )
    },

    getBatchConsumptionData(batchId, search, onSuccess, onError) {
        axios.get("/batches/" + batchId + "/consumption",{params: search}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    getBatchDiffConsumptionData(batchId, search, onSuccess, onError) {
        axios.get("/batches/" + batchId + "/diffConsumption",{params: search}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    getBatchFeedConsumptionAcc(batchId, search, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/batches/" + batchId + "/feedConsumptionAcc",{params: search}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    getBatchWaterConsumptionAcc(batchId, search, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/batches/" + batchId + "/waterConsumptionAcc",{params: search}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    getCurveConsumptionAcc(search, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/consumption-acc", {params: search}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    getRecalculatedBatchConsumptionData(batchId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/batches/calculateConsumption/" + batchId).then(
            response => {
                onSuccess(response.data);
            },error => {
                onError(error);
            });
    },

    refreshBatchCalculatedInfo(batchId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/batches/refreshCalculatedInfo/" + batchId).then(
            response => {
                onSuccess(response.data);
            },error => {
                onError(error);
            });
    },

    refreshBatchOnCloseCalculatedInfo(batchId, onSuccess, onError){
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/batches/refreshOnCloseCalculatedInfo/" + batchId).then(
            response => {
                onSuccess(response.data);
            },error => {
                onError(error);
            });
    },

    refreshBatchImportedData(batchId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get("/batches/refreshBatchImportedData/" + batchId).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    exportBatchAnalytics(batchId, onSuccess, onError){
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get("/batches/exportBatchAnalytics/" + batchId).then(
            response => {
                onSuccess(response.data);
            },error => {
                onError(error);
            });
    },

    getBatchEnvironmentKpis(search, onSuccess, onError){
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get("/batches/getEnvironmentKpis", {params: search}).then(
            response => {
                onSuccess(response.data);
            },error => {
                onError(error);
            });
    },

    getFeedMovements(search, onSuccess, onError) {
        axios.get('/batches/feedTransactions', {params: search})
            .then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            });
    },

    getTotalNumberOfAnimals(data, isNewTransaction) {
        if(isNewTransaction) {
            return _.toString(Number(data.numberAnimals) - Number(data.totalExitPending));
        } else {
            if (data.action === this.actions.ENTRY && _.isNil(data.inverseTransaction)) {
                return null;
            }
            let originBatch = data.action === this.actions.EXIT ? data.batch : data.inverseTransaction.batch;
            return _.toString(Number(originBatch.numberAnimals) - Number(originBatch.totalExitPending) + Number(data.number));
        }
    },

    getDailyFeedConsumption(batch){
        if(_.isNil(batch.siloInterfaceWeightConsumed)) {
            return '- - -';
        } else if(batch.batchAge === 0 || _.isNil(batch.batchAge) ) {
            return '- - -';
        } else if(batch.status && (batch.numberAnimals === 0 || _.isNil(batch.numberAnimals))) {
            return '- - -';
        } else if(!batch.status && (batch.totalExit === 0 || _.isNil(batch.totalExit))) {
            return '- - -';
        } else if(batch.status) {
            return NumberUtils.formatDecimalSeparator(Number(batch.siloInterfaceWeightConsumed)/Number(batch.totalEntry-batch.totalMortality)/Number(batch.batchAge),3);
        } else if(!batch.status) {
            return NumberUtils.formatDecimalSeparator(Number(batch.siloInterfaceWeightConsumed)/Number(batch.totalExit)/Number(batch.batchAge),3);
        }
    },

    getSpacePerAnimal(totalEntry,  entityArea){
        return NumberUtils.formatDecimalSeparator((Number(entityArea))/Number(totalEntry),2);
    },

    getAnimalPerSpace(totalEntry,  entityArea){
        return NumberUtils.formatDecimalSeparator((Number(totalEntry))/Number(entityArea),2);
    },

    getCurveDetailsListKey(curveId, age) {
        return 'curveDetails-' + curveId + '-age-' + age;
    },

    getWeightSuggestion(component){
        let weightPlaceHolder = component.props.batchData ? (component.props.batchData.productionCurve.productionCurveDetails.weight ? component.props.batchData.productionCurve.productionCurveDetails.weight : t('batches.details.transactions.form.fields.weight.placeholder')) :(component.state.batchData ? (component.state.batchData.productionCurve.productionCurveDetails ? (component.state.batchData.productionCurve.productionCurveDetails.weight ? component.state.batchData.productionCurve.productionCurveDetails.weight: t('batches.details.transactions.form.fields.weight.placeholder')) : t('batches.details.transactions.form.fields.weight.placeholder')) : t('batches.details.transactions.form.fields.weight.placeholder'));
        return weightPlaceHolder;
    },

    getAgeSuggestion(component){
        let agePlaceHolder = component.props.batchData ? (component.props.batchData.productionCurve.productionCurveDetails.age ? component.props.batchData.productionCurve.productionCurveDetails.age : t('batches.details.transactions.form.fields.age.placeholder')) :(component.state.batchData ? (component.state.batchData.productionCurve.productionCurveDetails ? (component.state.batchData.productionCurve.productionCurveDetails.age ? component.state.batchData.productionCurve.productionCurveDetails.age: t('batches.details.transactions.form.fields.age.placeholder')) : t('batches.details.transactions.form.fields.age.placeholder')) : t('batches.details.transactions.form.fields.age.placeholder'));
        return agePlaceHolder;
    },

    mapSearchDataLastTime(searchData) {
        if(searchData.lastTime){
            switch (searchData.lastTime) {
                case 'TWENTYFOUR':
                    searchData.fromDate = moment.utc().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'WEEK':
                    searchData.fromDate = moment.utc().subtract(1, 'week').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'MONTH':
                    searchData.fromDate = moment.utc().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                default:
                    searchData.fromDate = moment.utc().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss');
            }
            delete searchData.toDate;
            delete searchData.lastTime;
        }
    }
};

function getActionDescriptionFromTransaction(transaction) {
    let result;
    switch(transaction.actionType) {
        case BatchService.actions.EXTERNAL_ENTRY:
        case BatchService.actions.EXTERNAL_SALE:
            result = transaction.typeDescription;
            break;
        case BatchService.actions.TRANSFER:
            result = transaction.inverseTransaction.batch.entity.farmOfSector.displayName + ' | ' + transaction.inverseTransaction.batch.code;
            break;
        case BatchService.actions.DEATH:
            result = transaction.deathReason.displayName;
            break;
        case BatchService.actions.BUY:
            result = transaction.feedFactory.displayName;
            break;
        default:
            break;
    }
    return result;
}

export default BatchService;